







































































import Component, { mixins } from 'vue-class-component';
import { RawLocation } from 'vue-router';
import { Prop, Watch } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component
export default class BreadcrumbWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'BreadcrumbWidgetStore';

  @Prop({
    required: false,
    default: () => [],
  })
  protected readonly params!: Record<string, string>;

  private readonly separator = '›';

  private readonly threeDots = '...';

  private showMenus = false;

  private top = '';

  private left = '';

  private data: Array<{ text: string; collapse: boolean; route?: RawLocation }> = [];

  private index = 0;

  created(): void {
    this.setDataConfig();
    this.mapData();
  }

  @Watch('isReadyToDisplay')
  @Watch('$route.path')
  @Watch('params')
  private mapData(): void {
    this.data = [];
    if (this.isReadyToDisplay) {
      const parts = this.$route.path.split('/');
      const props = this.$route.params;
      if (parts.length > 1) {
        for (let index = 1; index < parts.length; index++) {
          let text = '';
          let route = '';
          const found = Object.values(props)
            .includes(parts[index]);
          if (found) {
            if (this.$route.meta && this.$route.meta.entityType && this.$route.meta.entityType === 'feedItemWrapper') {
              text = 'page.breadcrumb.post.text';
            } else if (Object.keys(this.params)
              .includes(parts[index])) {
              text = `${this.params[parts[index]]}`;
            } else {
              text = `${parts[index]}`;
            }
          } else {
            let label = (parts.slice(1, index + 1)).join('.');
            Object.keys(this.params)
              .forEach((k) => {
                label = label.replaceAll(k, 'dynamic-value');
              });
            text = `page.breadcrumb.${parts[index] === '' ? 'home' : label}.text`;
          }
          route = `/${parts.slice(1, index + 1)
            .join('/')}`;
          const resolved = this.$router.resolve(route);
          if (resolved.route.name !== 'not-found') {
            this.data.push({
              text,
              route,
              collapse: false,
            });
          }
        }
      }
      this.updateBreadcrumbs();
    }
  }

  @Watch('windowWidth.value')
  private updateBreadcrumbs(): void {
    this.$nextTick(() => {
      if (this.$el.hasChildNodes()) {
        this.data.forEach((b) => {
          b.collapse = false;
        });
        this.index = 0;
        const breadcrumbs = this.$el.querySelector('.breadcrumbs');
        if (breadcrumbs) {
          const fullBreadcrumbsWidth = breadcrumbs.getBoundingClientRect().width;
          const lastBreadcrumbsChild = breadcrumbs.lastElementChild;
          if (lastBreadcrumbsChild) {
            this.$nextTick(() => {
              while (lastBreadcrumbsChild.getBoundingClientRect().right + 20 > fullBreadcrumbsWidth
              && this.index < this.data.length - 2) {
                this.index += 1;
                this.data[this.index].collapse = true;
              }
            });
            this.updateMenuPosition();
          }
        }
      }
    });
  }

  private updateMenuPosition(): void {
    const menu = this.$el.querySelector('.three-dots');
    if (menu) {
      this.top = `${menu.getBoundingClientRect().bottom + 2}px`;
      this.left = `${menu.getBoundingClientRect().left - 8}px`;
    }
  }
}
